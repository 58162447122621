<template
  ><div id="autochartist">
    <div class="content-box">
      <div class="inner">
        <div class="main">
          <div class="box" v-if="showlive">
            <div class="top">
              <div class="shadow"></div>
              <div class="title">{{ $t('autochartist.1.title') }}</div>
            </div>
            <div class="bottom">
              <div class="content">{{ $t('autochartist.1.desc01') }}</div>
              <div class="left"></div>
              <div class="right">
                <a :href="metaTraderLink" target="_blank">
                  <el-button>{{ $t('autochartist.1.button01') }}</el-button>
                </a>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="top">
              <div class="shadow"></div>
              <div class="title">{{ $t('autochartist.2.title') }}</div>
            </div>
            <div class="bottom">
              <div class="content">{{ $t('autochartist.2.desc01') }}</div>
              <div class="left"></div>
              <div class="right">
                <a :href="marketReportLink" target="_blank">
                  <el-button>{{ $t('autochartist.2.button01') }}</el-button>
                </a>
              </div>
            </div>
          </div>
          <div class="box" v-if="showlive">
            <div class="top">
              <div class="shadow"></div>
              <div class="title">{{ $t('autochartist.3.title') }}</div>
            </div>
            <div class="bottom">
              <div class="content">{{ $t('autochartist.3.desc01') }}</div>
              <div class="left"></div>
              <div class="right">
                <a :href="launchLink" target="_blank">
                  <el-button>{{ $t('autochartist.3.button01') }}</el-button>
                </a>
                <a :href="userGuideLink" target="_blank">
                  <el-button>{{ $t('autochartist.3.button02') }}</el-button>
                </a>
                <a href="http://app.autochartist.com/otp" target="_blank">
                  <el-button>{{ $t('autochartist.3.button03') }}</el-button>
                </a>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="top">
              <div class="shadow"></div>
              <div class="title">{{ $t('autochartist.4.title') }}</div>
            </div>
            <div class="bottom">
              <div class="content">{{ $t('autochartist.4.desc01') }}</div>
              <div class="left"></div>
              <div class="right">
                <a :href="tradingOpportunitiesLink" target="_blank">
                  <el-button>{{ $t('autochartist.4.button01') }}</el-button>
                </a>
              </div>
            </div>
          </div>
          <div class="box qrcode" v-if="showlive">
            <div class="top">
              <div class="shadow"></div>
              <div class="title">{{ $t('autochartist.5.title') }}</div>
            </div>
            <div class="bottom">
              <div class="content">
                {{ $t('autochartist.5.desc01') }}
                <br /><br />
                <ul>
                  {{
                    $t('autochartist.5.desc02')
                  }}
                  <li>{{ $t('autochartist.5.desc03') }}</li>
                  <li>{{ $t('autochartist.5.desc04') }}</li>
                  <li>{{ $t('autochartist.5.desc05') }}</li>
                </ul>
              </div>
              <div class="left">
                <img :src="qrcodeLink" alt="" />
              </div>
              <div class="right">
                <a href="https://itunes.apple.com/us/app/autochartist/id903348229?mt=8" target="_blank">
                  <el-button>{{ $t('autochartist.5.button01') }}</el-button>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.autochartist.mobile" target="_blank"
                  ><el-button>{{ $t('autochartist.5.button02') }}</el-button>
                </a>
              </div>
            </div>
          </div>
          <div class="box" v-if="showlive">
            <div class="top">
              <div class="shadow"></div>
              <div class="title">{{ $t('autochartist.6.title') }}</div>
            </div>
            <div class="bottom">
              <div class="content">{{ $t('autochartist.6.desc01') }}</div>
              <div class="left"></div>
              <div class="right">
                <a :href="subscribeLink" target="_blank">
                  <el-button>{{ $t('autochartist.6.button01') }}</el-button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="more" v-if="!showlive">
          <img src="@/assets/images/autochartist/more.png" alt="more" />
          <router-link :to="{ name: 'register' }" class="el-button btn-red">Live Account </router-link>
        </div>
        <div class="footer">
          <img src="@/assets/images/autochartist/autochartist.png" alt="" />
          <span> {{ $t('autochartist.footer') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import idPoaMixin from '@/mixins/idPoa';

export default {
  name: 'autochartist',
  data() {
    return {
      domain: 'https://www.autochartist.com'
    };
  },
  mixins: [idPoaMixin],
  watch: {
    autochartisExpiryTime: {
      handler() {
        this.setExpiryTime();
        this.getToken();
      },
      immediate: true
    }
  },
  methods: {
    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    setExpiryTime() {
      if (!this.autochartisExpiryTime)
        this.$store.commit('common/setAutochartisExpiryTime', this.addDays(new Date(), 1).getTime());
    },
    getToken() {
      const data = {
        userType: this.userType,
        expiryTime: this.autochartisExpiryTime
      };
      this.$store.dispatch('common/actionAutochartistToken', data);
    }
  },
  computed: {
    autochartisToken() {
      return this.$store.state.common.autochartisToken;
    },
    autochartisExpiryTime() {
      return this.$store.state.common.autochartisExpiryTime;
    },
    userType() {
      return this.showlive ? 0 : 1;
    },
    account_type() {
      return this.userType === 0 ? 'LIVE' : 'DEMO';
    },
    showlive() {
      return (
        !this.requireIDProof &&
        !this.requireAddressProof &&
        !this.pendingIDProofApproval &&
        !this.pendingAddressProofApproval
      );
    },
    marketReportLink() {
      return `https://reports.autochartist.com/mr/?broker_id=722&rid=1&user=${this.autochartisToken.userId}&demo=${this.userType}&token=${this.autochartisToken.token}&expire=${this.autochartisExpiryTime}&css=https://www.autochartist.com/components-css/puprime_mr.css`;
    },
    launchLink() {
      return `https://pacificunion.autochartist.com/aclite/PacificUnionMain?username=${this.autochartisToken.userId}&account_type=${this.account_type}&expire=${this.autochartisExpiryTime}&logintoken=${this.autochartisToken.token}`;
    },
    tradingOpportunitiesLink() {
      return `https://component.autochartist.com/to/?broker_id=722&account_type=${this.account_type}&user=${this.autochartisToken.userId}&token=${this.autochartisToken.token}&expire=${this.autochartisExpiryTime}&trade_now=n&layout=vertical&css=https://www.autochartist.com/components-css/puprime_to.css`;
    },
    qrcodeLink() {
      return `https://component.autochartist.com/to/resources/mobile/qr/image/?broker_id=722&account_type=LIVE&user=${this.autochartisToken.userId}&token=${this.autochartisToken.token}&expire=${this.autochartisExpiryTime}`;
    },
    subscribeLink() {
      return `https://reports.autochartist.com/signup/?bid=722&user=${this.autochartisToken.userId}&demo=0&token=${this.autochartisToken.token}&expire=${this.autochartisExpiryTime}&css=https://www.autochartist.com/components-css/puprime_signup.css`;
    },
    userGuideLink() {
      let link;
      switch (this.lang) {
        case 'en_US':
          link = `https://www.autochartist.com/aclite_static_files/user-manual/the-autochartist-interface.html#i`;
          break;
        case 'zh_CN':
          link = `http://www.autochartist.com/aclite_static_files/user-manual-chinese/`;
          break;
        default:
          link = `https://www.autochartist.com/aclite_static_files/user-manual/the-autochartist-interface.html#i`;
      }
      return link;
    },
    metaTraderLink() {
      let link;
      switch (this.lang) {
        case 'en_US':
          link = `${this.domain}/metatrader-plugin/index_en.html`;
          break;
        case 'es':
          link = `${this.domain}/metatrader-plugin/index_es.html`;
          break;
        case 'ko':
          link = `${this.domain}/metatrader-plugin/index_ko.html`;
          break;
        case 'zh_CN':
          link = `${this.domain}/metatrader-plugin/index_ch.html`;
          break;
        case 'th':
          link = `${this.domain}/metatrader-plugin/index_th.html`;
          break;
        case 'vi':
          link = `${this.domain}/metatrader-plugin/index_vi.html`;
          break;
        default:
          link = `${this.domain}/metatrader-plugin/index_en.html`;
      }
      return link;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/autochartist.scss';
</style>
